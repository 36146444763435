import React, { useState, useEffect } from 'react'
import CollegialiaModel, { t_collegiaData, t_collegialiaCategory, t_collegialiaState } from './Components/Models/CollegialiaModel'
import CollegialiaList from './Components/CollegialiaList/CollegialiaList'
import { Grid, Paper, Typography } from '@material-ui/core'
import Footer from './Components/Partials/Footer'
import Navbar from './Components/Partials/Navbar'
import SearchBar, { t_searchParams } from './Components/SearchBar/SearchBar'
import Paginator from './Components/Utilities/Paginator'
import Alert from '@material-ui/lab/Alert'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import CollegieIcon from './Components/Utilities/CollegieIcon'

const AppStyle = makeStyles((theme) => (
    createStyles({
        welcomeBox: {
            padding: '1em',
            textAlign: 'justify',
        },
        restrictedInfo: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.contrastText,
            padding: '1em',
        },
    })))

function App() {
    const classes = AppStyle()
    const { t } = useTranslation('common')
    const [status, setStatus] = useState<'ready' | 'loading' | 'error'>('loading')
    const [accessState, setAccessState] = useState<t_collegialiaState>('public')
    const [collegialiaData, setCollegialiaData] = useState<t_collegiaData[]>([])
    const [searchParams, setSearchParams] =
        useState<t_searchParams>({
            title: '',
            category: '',
            state: ''
        })

    const getFilterData = () =>
        collegialiaData.filter((elem) => (
            (searchParams.title === '' || elem.title.indexOf(searchParams.title) !== -1) &&
            (searchParams.category === '' || elem.category === searchParams.category) &&
            (searchParams.state === '' || elem.state === searchParams.state)
        ))

    useEffect(() => {
        CollegialiaModel.getData()
            .then(
                ({ data, accessState }) => {
                    setCollegialiaData(() => (data))
                    setAccessState(accessState)
                    setStatus('ready')
                }
            )
            .catch(() => setStatus(() => 'error'))
    }, [])

    return (
        <Grid
            style={{ minHeight: '100vh', overflowX: 'hidden', }}
            container
            justify="space-between"
            alignItems="stretch"
            direction="column"
        >
            <Grid
                justify="flex-start"
                alignItems="stretch"
                container
                direction="column"
                spacing={4}
            >

                <Grid item >
                    <Navbar />
                </Grid>

                 <Grid item xs={11} style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }} >
                     <Paper className={classes.welcomeBox} elevation={2}>
                         <Grid
                             container
                         >
                             <Grid item xs={12}>
                                 <Typography>
                                     {t('text.welcome')}
                                 </Typography>
                             </Grid>
                         </Grid>
                    </Paper>
                </Grid>

                {
                    accessState === 'restricted'
                        ?
                        <Grid item xs={11} sm={8} md={6} xl={4} style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }} >
                            <Paper className={classes.restrictedInfo} elevation={2}>
                                <Grid
                                    container
                                >
                                    <Grid item xs={1} sm={'auto'} style={{ marginRight: '1em', }}>
                                        <CollegieIcon fontSize='large' />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography>
                                            {t('text.restrected.info')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        :
                        <></>
                }

                <Grid item >
                    <SearchBar searchParams={searchParams} setSearchParams={setSearchParams} accessState={accessState} />
                </Grid>

                 <Grid item xs={11} style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }} >
                     <Paper className={classes.welcomeBox} elevation={2}>
                         <Grid
                             container
                         >
                             <Grid item xs={12}>
                                 <Typography>
					<a href="https://eotvos.elte.hu/kiadvanyok" target="_blank">
						{t('text.publications')}
					</a>
                                 </Typography>
                             </Grid>
                         </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={11} style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>

                    {
                        status === 'error'
                            ?
                            <Alert variant="filled" severity="error" style={{ width: 'max-content', minWidth: '50%', margin: '0 auto' }} >
                                {t('error.loading.data')}
                            </Alert>
                            :
                            <Paginator<t_collegiaData, { loading: boolean }>
                                data={getFilterData()}
                                showCount={12 * 1}
                                passProps={{ loading: status !== 'ready' }}
                                makeChildren={({ data, props: { loading: localLoading } }) => <CollegialiaList accessState={accessState} data={data} loading={localLoading} />}
                            />
                    }
                </Grid>
            </Grid>

            <Grid item>
                <Footer />
            </Grid>
        </Grid >
    )
}

export default App;
