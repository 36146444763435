import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import common_en from './Localization/en/common.json'
import common_hu from './Localization/hu/common.json'

const resources = {
    en: {
        common: common_en
    },
    hu: {
        common: common_hu
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'hu',
        debug: true,

        keySeparator: false,

        interpolation: {
            escapeValue: false,
        }
    });

export default i18n;