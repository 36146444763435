import React, { useState } from 'react'
import { t_collegialiaCategory, t_collegialiaState, collegialiaCategoryValues, collegialiaStateValues } from '../Models/CollegialiaModel'
import { Paper, Grid, TextField, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import ToggleButton from '../Utilities/ToggleButton'
import { stringify } from 'querystring'

export type t_searchParams = {
    title: string,
    category: t_collegialiaCategory | '',
    state: t_collegialiaState | '',
}

export type t_searchBarProps = {
    searchParams: t_searchParams,
    setSearchParams: (_: t_searchParams) => void,
    accessState: t_collegialiaState,
}

const SearchBarStyle = makeStyles((theme) => (
    createStyles({
        container: {
            padding: '1em .5em',
            [theme.breakpoints.up('sm')]: {
                padding: '1em 2em 1em 2em',
            }
        },
        searchBarBox: {
            margin: '0em .5em .5em .5em',
        },
        searchBarInput: {
            fontSize: '1.5em',
        },
    })))

const SearchBar = ({ searchParams, setSearchParams, accessState }: t_searchBarProps) => {
    const { t } = useTranslation('common')
    const classes = SearchBarStyle()

    const setSearchValue = ({ name, value }: { name: 'category' | 'state' | 'title', value: string }) => {
        setSearchParams({
            ...searchParams,
            [name]: value,
        })
    }

    return (
        <Grid
            container
            justify="center"
        >
            <Grid item xs={11} sm={10} md={8} xl={7}>
                <Paper elevation={4} className={classes.container}>
                    <Box
                        className={classes.searchBarBox}
                    >
                        <TextField
                            label={t('label.search')}
                            variant='standard'
                            fullWidth
                            InputProps={{ classes: { input: classes.searchBarInput } }}
                            value={searchParams.title}
                            onChange={
                                (event) => setSearchValue({ name: 'title', value: event.target.value })
                            }
                        />
                    </Box>
                    <ToggleButton
                        onClick={() => setSearchValue({ name: 'category', value: '' })}
                        on={searchParams.category === ''}
                        label={t('label.all')}
                        size='small'
                    />
                    {
                        collegialiaCategoryValues.map((category) => (
                            <ToggleButton
                                onClick={() => setSearchValue({ name: 'category', value: category })}
                                on={searchParams.category === category}
                                label={t('collegialia.category.' + category)}
                                size='small'
                                key={category}
                            />
                        ))
                    }

		    <a href="https://eotvos.elte.hu/ujsagcikkek" target="_blank">
	                    <ToggleButton
	                        onClick={() => {}}
		                on={false}
				size='small'
				label={t('label.sajtotermek')}
		            />
		    </a>

                    {
                        accessState === 'restricted'
                            ?
                            <>
                                <br />
                                <ToggleButton
                                    onClick={() => setSearchValue({ name: 'state', value: '' })}
                                    on={searchParams.state === ''}
                                    label={t('label.all')}
                                    color='primary'
                                    size='small'
                                />
                                {
                                    collegialiaStateValues.map((state) => (
                                        <ToggleButton
                                            onClick={() => setSearchValue({ name: 'state', value: state })}
                                            on={searchParams.state === state}
                                            label={t('collegialia.state.' + state)}
                                            color='primary'
                                            size='small'
                                            key={state}
                                        />
                                    ))
                                }
                            </>
                            :
                            <></>
                    }


                </Paper>
            </Grid>
        </Grid>
    )
}

export default SearchBar
