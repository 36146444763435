import React, { useState, useEffect } from 'react'
import { Card, CardActionArea, CardMedia, CardContent, Typography, Grid } from '@material-ui/core'
import CollegialiaModel, { t_collegiaData } from '../Models/CollegialiaModel'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { useTranslation } from 'react-i18next'
import Skeleton from '@material-ui/lab/Skeleton'
import CollegieIcon from '../Utilities/CollegieIcon'

const transitionDuration: number = 500
const minHeight: number = 30
const CollegialiaListElementStyle = makeStyles((theme) => (
    createStyles({
        root: {
            minHeight: minHeight + 'em',

            '&:hover *': {
                opacity: 1,
            },
            '&:hover .MuiCardContent-root': {
                backgroundColor: (props: t_collegialiaListElement) =>
                    (props.accessState === 'restricted' || props.accessState === 'public' && props.data.state === 'public')
                    ? theme.palette.background.default
                    : 'black',
            }
        },
        title: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            padding: '.5em',
            //if the user cannot access it, it will be inverted
            backgroundColor: (props: t_collegialiaListElement) =>
                    (props.accessState === 'restricted' || props.accessState === 'public' && props.data.state === 'public')
                    ? theme.palette.background.default + 'c0'
                    : '#000000c0',
            color: (props: t_collegialiaListElement) =>
                    (props.accessState === 'restricted' || props.accessState === 'public' && props.data.state === 'public')
                    ? 'black' : 'white',
            transitionDuration: transitionDuration + 'ms',

        },
        viewOverlay: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            backgroundColor: theme.palette.grey[900] + 'c0',
            top: 0,
            left: 0,
            opacity: 0,

            transitionDuration: '500ms',

        },
        accessDenied: {
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: '.5em',
            paddingBottom: '.25em',
            backgroundColor: '#000000c0',
            color: 'white',
            fontSize: '2em',
            opacity: 0,
            transitionDuration: '750ms',
        },
        viewIcon: {
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: '.5em',
            paddingBottom: '.25em',
            backgroundColor: theme.palette.background.default + 'c0',
            borderRadius: '50%',
            opacity: 0,
            transitionDuration: '750ms',


            '& svg': {
                fontSize: '3em',
            }
        },
        loadingOverlay: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
        },
        errorOverlay: {
            width: '100%',
            height: '100%',
        },
        errorText: {
            //color:'red',
        },
    })))

export type t_collegialiaListElement = {
    accessState: string,   //the global access rights of the user
    data: t_collegiaData,
}
const CollegialiaListElement = (props: t_collegialiaListElement) => {
    const accessState = props.accessState
    const data = props.data
    const classes = CollegialiaListElementStyle(props)
    const [status, setStatus] = useState<'ready' | 'loading' | 'error'>('loading')
    const { t } = useTranslation('common')
    //whether the user is authorised to see the content
    const isEnabled = accessState === 'restricted' || accessState === 'public' && data.state === 'public'


    const loadingSuccess = () => setStatus('ready')
    const loadingError = () => setStatus('error')

    return (
        <Card key={data.cover} >
            <CardActionArea
                className={classes.root}
                // if the file path has been removed, let it be empty
                href={(data.file === '') ? '' : (CollegialiaModel.translateToUrlFile({ file: data.file }))}
                target='_blank'
            >
                <CardMedia
                    component='img'
                    alt={data.title}
                    width={'100%'}
                    image={CollegialiaModel.translateToUrlCover({ file: data.cover })}

                    style={{ visibility: (status === 'ready' ? 'visible' : 'hidden'), minHeight: minHeight + 'em', }}

                    onLoad={loadingSuccess}
                    onError={loadingError}
                />
                {
                    status === 'ready'
                        ?
                        <Grid
                            container
                            className={classes.viewOverlay}
                            justify="center"
                            direction="column"
                        >
                            {
                                isEnabled
                                ?
                                <Grid item className={classes.viewIcon}>
                                    <VisibilityIcon />
                                </Grid>
                                :
                                <div className={classes.accessDenied}>{t('text.access_denied')}</div>
                            }
                        </Grid>
                        :
                        <></>
                }

                {
                    status === 'loading'
                        ?
                        <Skeleton
                            variant='rect'
                            className={classes.loadingOverlay}
                            animation='wave'
                        />
                        :
                        <></>
                }
                {
                    status === 'error'
                        ?
                        <Grid
                            container
                            className={classes.errorOverlay}
                            justify="center"
                            direction="column"
                        >
                            <Grid item className={classes.errorText}>
                                <Typography align='center' variant='body2' >
                                    {t('error.loading.image')}
                                </Typography>
                            </Grid>
                        </Grid>
                        :
                        <></>
                }

                <CardContent className={classes.title}>
                    <Typography variant='h5' component='h2'>
                        {
                            data.state === 'restricted'
                                ?
                                (
                                    isEnabled
                                    ?
                                    <><CollegieIcon color='primary' fontSize='inherit' />&nbsp;</>
                                    :
                                    <><CollegieIcon fontSize='inherit' />&nbsp;</>
                                )
                                :
                                <></>
                        }
                        {data.title}
                    </Typography>
                </CardContent>
            </CardActionArea>
            {/* <pre>
                {JSON.stringify(status, null, 4)}
            </pre> */}
        </Card>
    )
}

export default CollegialiaListElement
