import React from 'react'
import { Typography, Box } from '@material-ui/core'
import { t_collegiaData } from '../Models/CollegialiaModel'
import CollegialiaListContainer from './CollegialiaListContainer'
import CollegialiaListLoading from './CollegialiaListLoading'
import { useTranslation } from 'react-i18next'

export type t_collegialiaListProps = {
    accessState: string, //the global access rights of the user
    loading: boolean,
    data: t_collegiaData[],
}
const CollegialiaList = ({ accessState, data, loading }: t_collegialiaListProps) => {
    const { t } = useTranslation('common')

    if (loading)
        return <CollegialiaListLoading />
    else
        if (data.length === 0)
            return (
                <Typography variant='body1' style={{ textAlign: 'center' }} >
                    {t('text.noDataToShow')}
                </Typography>
            )
        else
            return <CollegialiaListContainer accessState={accessState}
                                             data={data} />

}

export default CollegialiaList
