import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import CssBaseline from '@material-ui/core/CssBaseline'
import i18n from './i18n'
import { ThemeProvider } from './Components/Providers/ThemeProvider'
import { I18nextProvider } from "react-i18next"


ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
