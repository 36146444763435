import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const CollegieIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox='0 0 512 512'>
        <path d="m511.929688 262.164062c-.003907-.042968-.003907-.085937-.007813-.132812-.003906-.039062-.011719-.078125-.019531-.113281-.050782-.484375-.125-.964844-.222656-1.433594l-22.464844-107.832031c-1.449219-6.953125-7.582032-11.9375-14.6875-11.9375h-174.636719l-28.890625-32.386719v-17.832031h75.003906c5.53125 0 10.613282-3.046875 13.222656-7.921875 2.613282-4.878907 2.324219-10.796875-.742187-15.398438l-14.453125-21.679687 14.453125-21.675782c3.066406-4.601562 3.355469-10.519531.742187-15.398437-2.609374-4.875-7.691406-7.921875-13.222656-7.921875h-90.003906c-8.285156 0-15 6.714844-15 15v92.792969l-29.167969 32.421875h-174.359375c-7.105468 0-13.238281 4.984375-14.6875 11.941406l-22.464844 107.828125c-.097656.46875-.171874.949219-.2226558 1.433594-.0039062.039062-.015625.078125-.0195312.113281-.0039062.046875-.0039062.089844-.0078125.132812-.046875.480469-.0703125.957032-.0703125 1.433594v233.878906c0 8.285157 6.714844 15 15 15h482c8.285156 0 15-6.714843 15-15v-233.878906c0-.476562-.023438-.953125-.070312-1.433594zm-49.597657-91.449218 16.222657 77.855468h-82.445313l-69.457031-77.855468zm-148.808593-116.898438 4.453124 6.679688h-46.976562v-29.996094h46.976562l-4.453124 6.675781c-3.359376 5.039063-3.359376 11.601563 0 16.640625zm-263.855469 116.898438h135.175781l-70.046875 77.855468h-81.351563zm220.765625 311.761718h-30v-52.984374h30zm211.566406 0h-181.566406v-67.984374c0-8.285157-6.714844-15-15-15h-60c-8.285156 0-15 6.714843-15 15v67.984374h-180.433594v-203.90625h91.480469c4.25 0 8.304687-1.804687 11.148437-4.964843l123.324219-137.078125 122.242187 137.03125c2.84375 3.1875 6.917969 5.011718 11.191407 5.011718h92.613281zm0 0" />
        <path d="m421.824219 369.492188c8.28125 0 15-6.714844 15-15v-19.996094c0-8.285156-6.71875-15-15-15-8.285157 0-15 6.714844-15 15v19.996094c0 8.285156 6.714843 15 15 15zm0 0" />
        <path d="m421.824219 449.492188c8.28125 0 15-6.71875 15-15v-20c0-8.285157-6.71875-15-15-15-8.285157 0-15 6.714843-15 15v20c0 8.28125 6.714843 15 15 15zm0 0" />
        <path d="m361.824219 319.496094c-8.285157 0-15 6.714844-15 15v19.996094c0 8.285156 6.714843 15 15 15 8.28125 0 15-6.714844 15-15v-19.996094c0-8.285156-6.71875-15-15-15zm0 0" />
        <path d="m361.824219 399.492188c-8.285157 0-15 6.714843-15 15v20c0 8.28125 6.714843 15 15 15 8.28125 0 15-6.71875 15-15v-20c0-8.285157-6.71875-15-15-15zm0 0" />
        <path d="m225.25 319.496094c-8.285156 0-15 6.714844-15 15v19.996094c0 8.285156 6.714844 15 15 15s15-6.714844 15-15v-19.996094c0-8.285156-6.714844-15-15-15zm0 0" />
        <path d="m286.75 319.496094c-8.285156 0-15 6.714844-15 15v19.996094c0 8.285156 6.714844 15 15 15s15-6.714844 15-15v-19.996094c0-8.285156-6.714844-15-15-15zm0 0" />
        <path d="m86.425781 319.496094c-8.28125 0-15 6.714844-15 15v19.996094c0 8.285156 6.71875 15 15 15 8.285157 0 15-6.714844 15-15v-19.996094c0-8.285156-6.714843-15-15-15zm0 0" />
        <path d="m86.425781 399.492188c-8.28125 0-15 6.714843-15 15v20c0 8.28125 6.71875 15 15 15 8.285157 0 15-6.71875 15-15v-20c0-8.285157-6.714843-15-15-15zm0 0" />
        <path d="m146.425781 319.496094c-8.28125 0-15 6.714844-15 15v19.996094c0 8.285156 6.71875 15 15 15 8.285157 0 15-6.714844 15-15v-19.996094c0-8.285156-6.714843-15-15-15zm0 0" />
        <path d="m146.425781 399.492188c-8.28125 0-15 6.714843-15 15v20c0 8.28125 6.71875 15 15 15 8.285157 0 15-6.71875 15-15v-20c0-8.285157-6.714843-15-15-15zm0 0" />
        <path d="m300.433594 240.4375c0-24.8125-20.1875-45-45-45s-45 20.1875-45 45 20.1875 45 45 45 45-20.1875 45-45zm-45 15c-8.269532 0-15-6.730469-15-15s6.730468-15 15-15c8.269531 0 15 6.730469 15 15s-6.730469 15-15 15zm0 0" />
    </SvgIcon>
)

export default CollegieIcon