import React, { useState } from 'react'
import Pagination from '@material-ui/lab/Pagination'
import { Grid } from '@material-ui/core'

type t_paginatorParams<T = any, P = any> = {
    makeChildren: (_: { data: T[], props: P }) => JSX.Element,
    data: T[],
    showCount: number,
    passProps: P,
}
const Paginator = <T extends unknown, P extends unknown>({ makeChildren, data, showCount, passProps }: t_paginatorParams<T, P>) => {
    const [pageIndex, setPageIndex] = useState<number>(1)
    const pageCount = Math.ceil(data.length / showCount)
    const passData = ((data as any).slice(showCount * (pageIndex - 1), showCount * (pageIndex - 1) + showCount))

    if (pageIndex > pageCount && pageCount >= 1)
        setPageIndex(1)

    return (
        <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="center"
            spacing={4}
        >
            <Grid
                item
            >
                <Pagination
                    style={{ marginLeft: 'auto', marginRight: 'auto', }}
                    count={pageCount}
                    page={pageIndex}
                    onChange={(_, v) => setPageIndex(v)}
                    color="secondary"
                // size="large"
                />
            </Grid>
            <Grid xs={12} item style={{ width: '100%' }}>
                {
                    makeChildren({
                        data: passData,
                        props: passProps,
                    })
                }
            </Grid>
            <Grid item>
                <Pagination count={pageCount} page={pageIndex} onChange={(_, v) => setPageIndex(v)} />
            </Grid>
        </Grid >
    )
}

export default Paginator