import { GLOBALS } from '../../Globals'

const sleep = async (ms: number) => await new Promise(r => setTimeout(r, ms))

export default class CollegialiaModel {
    private static urlBase = GLOBALS.BackendUrl

    public static uploadsUrl: string = CollegialiaModel.urlBase + '/get-file'

    public static translateToUrlFile = ({ file }: { file: string }) =>
        `${CollegialiaModel.uploadsUrl}/file/${file}`

    public static translateToUrlCover = ({ file }: { file: string }) =>
        `${CollegialiaModel.uploadsUrl}/cover/${file}`

    public static getData = async (): Promise<{ data: t_collegiaData[], accessState: t_collegialiaState }> =>
        await fetch(CollegialiaModel.urlBase + '/list')
            .then((res: any) => res.json())
            .catch((_) => { throw Error('error.loading.data') })
            .then(({ data, accessState }: { data: t_collegiaData[], accessState: t_collegialiaState }) => ({ data, accessState }))
            .catch((_) => { throw Error('error.loading.data') })
}

export type t_collegiaData = {
    title: string,
    category: t_collegialiaCategory,
    cover: string,
    file: string,
    state: t_collegialiaState,
}


export const collegialiaCategoryValues: t_collegialiaCategory[] =
    ['collegi', 'studies', 'students', 'fineart']

export const collegialiaStateValues: t_collegialiaState[] =
    ['public', 'restricted']

export type t_collegialiaCategory =
    'collegi' | 'studies' | 'fineart' | 'students'

export type t_collegialiaState =
    'public' | 'restricted'
