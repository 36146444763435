import React from 'react'
import { Typography, Grid, Button, Box } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import HomeIcon from '@material-ui/icons/Home'
import { GLOBALS } from '../../Globals'

const FooterStyle = makeStyles((theme) => (
    createStyles({
        root: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            borderRadius: 0,
            padding: '1em',
            marginTop: '2em',
        },
        nkaLogo: {
            height: '2em',
            float: 'left',
        },
        nkaAck: {
            display: 'inline-block',
            marginLeft: '1em',
            paddingTop: '0.1em',
        }
    })))

const Footer = () => {
    const classes = FooterStyle()
    const { t } = useTranslation('common')

    return (
        <Grid
            className={classes.root}
            container
            direction="row"
            justify="space-between"
            alignItems="baseline"
        >
            <Grid
                item
            >
                <Button
                    startIcon={<HomeIcon />}
                    color='inherit'
                    href={GLOBALS.mainPageURL}
                >
                    {t('label.mainWebPage')}
                </Button>
            </Grid>
            <Grid
                item
            >
	        <Box pl={1}>
                    <img className={classes.nkaLogo} src="/nka_2023(2).png" alt="NKA-logó" />
                    <Typography variant='body1' className={classes.nkaAck} >
                        {t('text.nkaAck')}
                    </Typography>
                </Box>
            </Grid>
            <Grid
                item
            >
                <Box pl={1}>
                    <Typography variant='body1' >
                        {t('text.allRigtsReserved')}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Footer