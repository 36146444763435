import React from 'react'
import { Typography, Button, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'

const CollegialiaListLoading = () => {
    const { t } = useTranslation('common')

    return (
        <Typography align='center'  >
            <Button
                startIcon={<HourglassEmptyIcon />}
                disableRipple={true}
                disableElevation={true}
                disableFocusRipple={true}
                disableTouchRipple={true}
                size='large'
            >
                {t('label.loading.data')}
            </Button>
        </Typography>
    )
}

export default CollegialiaListLoading