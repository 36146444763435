import React, { useState } from 'react'
import { Paper, Grid, TextField, Button } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'

const ToggleButtonStyle = makeStyles((theme) => (
    createStyles({
        root: {
            margin: '.25em',
        },
    })))


type t_toggleButtonProps = {
    onClick: (_: React.MouseEvent) => void,
    on: boolean,
    label: string,
    color?: 'primary' | 'secondary'
}
const ToggleButton = <T extends t_toggleButtonProps>({ onClick, on, label, color, ...props }: T) => {
    const classes = ToggleButtonStyle()

    return (
        <Button
            {...props}
            onClick={onClick}
            color={color ?? 'secondary'}
            className={classes.root}
            {
            ...on ?
                ({
                    variant: 'contained'
                })
                :
                ({
                    variant: 'outlined'
                })
            }

        >
            {label}
        </Button>
    )
}

export default ToggleButton

