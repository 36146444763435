import React from 'react'
import { AppBar, Toolbar, Grid, Typography, Box, Button, Link, Hidden } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import HomeIcon from '@material-ui/icons/Home'
import { GLOBALS } from '../../Globals'


const NavbarStyle = makeStyles((theme) => (
    createStyles({
        root: {
            //            padding: '1em',
            padding: '.5em',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
        navbar: {
        },
        titleMain: {
            margin: '0em',
            fontSize: '1.8em',
            fontWeight:'lighter',
        },
        logo: {
            marginRight: '1em',
            [theme.breakpoints.up('sm')]: {
                height: '5em',
            },
            [theme.breakpoints.only('xs')]: {
                width: '100%',
            },
        },
        button: {
            color: theme.palette.primary.contrastText,
            leftMargin:'auto',
            marginTop:'1em',
        },
    })))


const Navbar = () => {
    const { t } = useTranslation('common')
    const classes = NavbarStyle()

    return (
        <>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                className={classes.root}
            >
                <Grid item xs={12} sm={2} md={'auto'} >
                    <img src="/logo.png" className={classes.logo} />
                </Grid>

                <Grid item xs={12} sm={9} md={'auto'} >
                    <Box>
                        <Typography component='h1' className={classes.titleMain} >
                            {t('page.title')}
                        </Typography>
                        <Typography variant='subtitle1'>
                            {t('text.eotvosCollegium')}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={10} sm={'auto'} style={{ flexGrow: 1 }}>
                </Grid>

                <Grid item xs={12} md={'auto'} sm={1}>
                    {/* <div style={{ flexGrow: 1 }} /> */}
                    <Button
                        startIcon={<HomeIcon />}
                        size='large'
                        href={GLOBALS.mainPageURL}
                        className={classes.button}
                    >
                        <Hidden only='sm' >
                        {t('label.mainWebPage')}
                        </Hidden>
                    </Button>
                </Grid>
            </Grid>


            {/* <AppBar position='static' className={classes.root} color='primary' >
                <Toolbar variant='regular' className={classes.navbar}>
                <img src="/logo.png" className={classes.logo} />
                <br/>
                <Box>
                    <Typography component='h1' className={classes.titleMain} >
                        {t('page.title')}
                    </Typography>
                    <Typography variant='subtitle1'>
                        {t('text.eotvosCollegium')}
                    </Typography>
                </Box>
                <div style={{ flexGrow: 1 }} />
                <Hidden smDown>
                    <Button
                        startIcon={<HomeIcon />}
                        size='large'
                        href={GLOBALS.mainPageURL}
                        className={classes.button}
                    >
                        {t('label.mainWebPage')}
                    </Button>
                </Hidden>
                </Toolbar>
            </AppBar> */}
        </>
    )
}

export default Navbar
