import React, { useState, createContext } from 'react'
import { ThemeProvider as MaterialThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const ThemeContext = createContext<{
    toggleDarkMode: () => void,
}>({
    toggleDarkMode: () => { },
})

export default ThemeContext

export const ThemeProvider = ({ children }: { children: any }) => {
    const [isDarkMode, setIsDarkMode] = useState<boolean>(useMediaQuery('(prefers-color-scheme: dark)'))
    const themeType = isDarkMode ? 'dark' : 'light'

    const switchThemeType = () => setIsDarkMode((prevValue) => (!prevValue))

    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#242851',
            },
            secondary: {
                main: '#b38f2f',
            },
            type: themeType,
        },
        typography: {
            h2: {
                fontSize: '2em',
                fontWeight: 'lighter',
                marginBottom: '1.5em',
            },
            h3: {
                fontSize: '1.5em',
                fontWeight: 'lighter',
                marginBottom: '1em',
                marginTop: '2em',
            },

        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    th: {
                        fontWeight: 'bold',
                        fontSize: '1.3em !important',
                        textTransform: 'capitalize',
                    },
                },

            }
        },
    })

    return (
        <ThemeContext.Provider value={{ toggleDarkMode: switchThemeType }}>
            <MaterialThemeProvider theme={theme}>
                {children}
            </MaterialThemeProvider>
        </ThemeContext.Provider>
    )
}
