import React from 'react'
import { t_collegiaData } from '../Models/CollegialiaModel'
import { Grid } from '@material-ui/core'
import CollegialiaListElement from './CollegialiaListElement'

export type t_collegialiaListContainerProps = {
    accessState: string,
    data: t_collegiaData[],
}
const CollegialiaListContainer = ({ accessState, data }: t_collegialiaListContainerProps) => {


    return (
        <Grid
            container
            justify="center"
            alignItems="stretch"
            spacing={1}
        >
            {
                data.map((elem) => (
                    <Grid item xs={12} sm={4} xl={2} md={3} style={{ maxWidth: '750px' }}>
                        <CollegialiaListElement accessState={accessState}
                                                data={elem} />
                    </Grid>
                ))
            }
        </Grid>
    )
}

export default CollegialiaListContainer
